/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you import will output into a single css file (app.css in this case)
require('./scss/app.scss');

var $ = require('jquery');
jQuery = global.$ = global.jQuery = $;

import './styles/app.css';



require("jquery-mousewheel");
require("jquery-ui/ui/widgets/autocomplete");
require('malihu-custom-scrollbar-plugin');
require('popper.js');
/*require('util');*/

require('bootstrap');
require('select2');
require('bootstrap-datepicker');
require('bootstrap-datepicker/dist/locales/bootstrap-datepicker.de.min.js');
require('bootstrap-datepicker/dist/locales/bootstrap-datepicker.es.min.js');
require('bootstrap-datepicker/dist/locales/bootstrap-datepicker.it.min.js');
require('@kanety/jquery-simple-tree-table');
require('jquery-tags-input/dist/jquery.tagsinput.min');

require('./js/app/jquery.sidebar');
require('./js/app/jquery.signin');
require('./js/app/jquery.ticket-list');
require('./js/app/jquery.ticket-detail');
require('./js/app/jquery.ticket-filters');
require('./js/app/jquery.auto-complete');
require('./js/app/jquery.user2fa-list');


$(document).sidebar();
$(document).ticketList();
$(document).ticketFilters();
$(document).signin();
$(document).autoComplete();
$(document).user2FA();
$('.is-ctl-ticket.is-act-detail').ticketDetail();
$('[data-toggle="tooltip"]').tooltip();
$('table').simpleTreeTable({
    margin: 0,
    iconPosition: 'td:first-of-type',
});
