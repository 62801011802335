import { Chart, registerables } from 'chart.js';

Chart.register(...registerables);

console.log(Chart);
(function ($, window, document, undefined) {
    "use strict";

    var pluginName = "ticketFilters",
        defaults = {},
        me;

    function Plugin(element, options) {
        me = this,
            me.$element = $(element),
            me.settings = $.extend({}, defaults, options),
            me._defaults = defaults,
            me._name = pluginName;

        me.init();
    }

    $.extend(Plugin.prototype, {
        init: function () {
            $.fn.select2.defaults.set("theme", "bootstrap4");
            $('select.select2:not(.customer)').select2({
                width: '100%',
                theme: "bootstrap4"
            });
            $('select.select2.customer').select2({
                width: '100%',
                theme: "bootstrap4",
                matcher: me.customerMatcher
            });

            $('select.select2.customer').on('select2:select', function (e) {

                me.loadRadarChart();
            });

            $('select.select2.ticketType').on('change', function (e) {

                me.loadRadarChart();
            });


            $('button[type="reset"]').on('click', me.onResetButtonClick);
            $('#toggle-filter-container').on('click', me.onToggleFiltersClick);

            if ($('body').hasClass('is-act-project-leader')){
                $('#btPrintDocument').click(function(){
                    me.documentGeneration();
                });
            }
        },
        onToggleFiltersClick: function (event) {
            var $button = $(this);

            $.each($button.find('span'), function () {
                var $span = $(this);

                $span.toggleClass('d-none');
            });
        },
        onResetButtonClick: function (event) {
            event.preventDefault();

            var $form = $(this).closest('form'),
                $select2 = $form.find('select.select2'),
                $fields = $form.find('input, textarea, select');

            $fields.val('');
            $select2.trigger('change');
        },
        customerMatcher: function (params, data) {
            // Always return the object if there is nothing to compare
            if ($.trim(params.term) === '') {
                return data;
            }

            if ($(data.element).data('matchcode') !== undefined) {
                var matchCode = $(data.element).data('matchcode').toString().toLowerCase(),
                    searchTerm = params.term.toLowerCase();

                if (matchCode.indexOf(searchTerm) === 0) {
                    return data;
                }
            }

            // If it doesn't contain the term, don't return anything
            return null;
        },
        loadRadarChart : function(){
            const form = $('form[name=project_leader_report]');
            const url = $(form).data('url');

            if (Chart.getChart('myChart') != undefined){
                Chart.getChart('myChart').destroy();
            }
            $('#loading').addClass('d-flex');
            $('#loading').removeClass('d-none');


            var values = {
                'customer': $('#project_leader_report_customer').val(),
                'ticket_type': $('#project_leader_report_ticketType').val(),
                'bussiness_part': $('#project_leader_report_businessPart').val(),
                'product': $('#project_leader_report_product').val(),
            };
            $.ajax({
                url: url,
                type: 'POST',
                data: values,
                success: function (response) {

                    if (response.labels !== undefined) {

                        const data = {
                            labels: response.labels,
                            datasets: [{
                                label: response.dataset_label,
                                data: response.dataset_values,
                                fill: true,
                                backgroundColor: 'rgba(255, 99, 132, 0.2)',
                                borderColor: 'rgb(255, 99, 132)',
                                pointBackgroundColor: 'rgb(255, 99, 132)',
                                pointBorderColor: '#fff',
                                pointHoverBackgroundColor: '#fff',
                                pointHoverBorderColor: 'rgb(255, 99, 132)'
                            }]
                        };
                        const config = {
                            type: 'radar',
                            data: data,
                            options: {
                                elements: {
                                    line: {
                                        borderWidth: 2
                                    }
                                }
                            },
                        };
                        var myChart = new Chart(
                            document.getElementById('myChart'),
                            config
                        );

                        me.loadMultiSeriesChart(response);
                    }
                },
                error: function () {
                    console.log('error');
                },
                complete: function (e) {
                    $('#loading').removeClass('d-flex');
                    $('#loading').addClass('d-none');
                }
            });

        },
        loadMultiSeriesChart : function(response){
            if (Chart.getChart('MultiSeries') != undefined){
                Chart.getChart('MultiSeries').destroy();
            }

            $('#loading2').addClass('d-flex');
            $('#loading2').removeClass('d-none');

            const DATA_COUNT = 5;
            const NUMBER_CFG = {count: DATA_COUNT, min: 0, max: 100};



            const data = {
                labels: response.labels_multiseries,
                datasets: response.datasets_multiseries
            };

            console.log(data);

            const config = {
                type: 'pie',
                data: data,
                options: {
                    responsive: true,
                    plugins: {
                        legend: {
                            labels: {
                                generateLabels: function(chart) {
                                    // Get the default label list
                                    const original = Chart.overrides.pie.plugins.legend.labels.generateLabels;
                                    const labelsOriginal = original.call(this, chart);

                                    // Build an array of colors used in the datasets of the chart
                                    var datasetColors = chart.data.datasets.map(function(e) {
                                        return e.backgroundColor;
                                    });
                                    datasetColors = datasetColors.flat();

                                    // Modify the color and hide state of each label
                                    labelsOriginal.forEach(label => {
                                        // There are twice as many labels as there are datasets. This converts the label index into the corresponding dataset index
                                        label.datasetIndex = (label.index - label.index % 2) / 2;

                                        // The hidden state must match the dataset's hidden state
                                        label.hidden = !chart.isDatasetVisible(label.datasetIndex);

                                        // Change the color to match the dataset
                                        label.fillStyle = datasetColors[label.index];
                                    });

                                    return labelsOriginal;
                                }
                            },
                            onClick: function(mouseEvent, legendItem, legend) {
                                // toggle the visibility of the dataset from what it currently is
                                legend.chart.getDatasetMeta(
                                    legendItem.datasetIndex
                                ).hidden = legend.chart.isDatasetVisible(legendItem.datasetIndex);
                                legend.chart.update();
                            }
                        },
                        tooltip: {
                            callbacks: {
                                label: function(context) {
                                    const labelIndex = (context.datasetIndex * 2) + context.dataIndex;
                                    return context.chart.data.labels[labelIndex] + ': ' + context.formattedValue;
                                }
                            }
                        }
                    }
                },
            };

            var MultiSeries = new Chart(
                document.getElementById('MultiSeries'),
                config
            );

            $('#loading2').removeClass('d-flex');
            $('#loading2').addClass('d-none');
        },
        documentGeneration : function(){
            /*const values = {
                'customer': $('#project_leader_report_customer').val(),
                'ticket_type': $('#project_leader_report_ticketType').val(),
                'bussiness_part': $('#project_leader_report_businessPart').val(),
                'product': $('#project_leader_report_product').val(),
            };
            console.log(values);*/
            const values = $('#formProjectLeader').serializeArray();
            const url = $('#formProjectLeader').attr('actiion');

            $.ajax({
                url: url,
                type: 'GET',
                data: values,
                success: function (response) {
                    console.log(response);
                    var anchor = document.createElement('a');
                    anchor.href = response.path;
                    anchor.download = response.realname;
                    document.body.appendChild(anchor);
                    anchor.click();
                    $('#loadingPrintModal').modal('hide');
                },
                error: function () {
                    $('#loadingPrintModal').modal('hide');
                   alert('Error to print document');
                },
                complete: function(){

                }
            });

        }
    });

    $.fn[pluginName] = function (options) {
        return this.each(function () {
            if (!$.data(this, "plugin_" + pluginName)) {
                $.data(this, "plugin_" +
                    pluginName, new Plugin(this, options));
            }
        });
    };

})(jQuery, window, document);
